@import "./base/variables";
@import "./vendor/bootstrap";

body {
  background-image: linear-gradient(180deg, #eee, #fff 100px, #fff);
	min-height: 100vh;
}

.container {
  max-width: 960px;
}

header {
	background: #b5c4bb;
	padding: 0px 18px;
}

.nav-link {
	color: #3e4144;
}

.nav-link:hover {
	text-decoration: underline !important;
}


nav {
	.nav-link:hover {
		text-decoration: underline !important;
	}

	.nav-link.active {
		color: #000 !important;
	}
}

.site-image {
	max-width: 100%;
}

.page-header {
  max-width: 700px;
}

/* References */

.references-container {
	max-width: 95%;
}